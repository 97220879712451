<template>
  <div class="col-xl-9 col-lg-12 col-md-12">
    <div class="card mb-0">
      <div class="card-header">
        <router-link
          :to="'/profile/education/update/' + $route.params.id"
          type="button"
        >
          <i class="dropdown-icon si si-arrow-left"></i>
        </router-link>
        <h3 class="card-title">Eğitim Planı</h3>
        <div class="card-options">
          <router-link
            :to="'/profile/education/update/files/' + $route.params.id"
            class="btn btn-primary"
            >Dosya Listesi</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-12">
            <button class="btn btn-primary float-right" @click="newGroup">
              Grup Oluştur
            </button>
          </div>
        </div>
        <div class="row">
          <h2>Gruplar</h2>

          <div
            v-for="(item, i) in groups"
            class="col-md-2 col-6"
            :key="i"
            @click="editGroupSetting(i)"
          >
            <div class="ffolder small pink">
              <span>{{ item.join.length }} Kişi</span>
            </div>
            <br />
            <div
              style="
                padding: 4px;
                background: #bb7494;
                border-radius: 7px;
                font-size: 12px;
                color: white;
                width: fit-content;
              "
            >
              {{ item.title }}
            </div>
          </div>
        </div>
        <hr />
        <div class="row">
          <h2>Tarihler</h2>
          <div
            v-for="(item, i) in days"
            :class="
              'col-md-2 col-sm-4 grid ' +
              (typeof item.selectedgroup !== 'undefined' ||
              createdCalendar[item.id] != null
                ? 'active'
                : '')
            "
            :key="i"
          >
            <p @click="dateSelectPlan(item)">
              <small>{{ item.title }}</small
              ><br />{{ item.day_name }}
            </p>
            <button
              class="btn btn-sm btn-primary"
              style="position: absolute; left: 2px; bottom: 2px"
              @click="dateSelectPlan(item)"
            >
              <i class="fa fa-eye"></i>
            </button>
            <template v-for="(i, a) in createdCalendar[item.id]">
              <button
                v-if="i.online == 1"
                :key="a"
                class="btn btn-sm btn-primary"
                style="
                  position: absolute;
                  left: 37px;
                  bottom: 2px;
                  z-index: 1040;
                "
                @click="openVideoCall(i)"
              >
                <i class="fa fa-video-camera"></i>
              </button>
            </template>

            <button
              class="btn btn-sm btn-primary"
              style="position: absolute; right: 2px; bottom: 2px; z-index: 1040"
              @click="dateSelect(item)"
            >
              <i class="fa fa-plus"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button
          type="button"
          @click="save"
          id="saveButton"
          class="btn btn-primary float-right"
        >
          Planı Kaydet
        </button>
      </div>
    </div>
    <!--MODAL-->
    <div class="modal" id="groupItemModal" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{
                selectedItem.title == "" ? "Başlıksız Grup" : selectedItem.title
              }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Grup Başlığı</label>
              <input
                type="text"
                v-model="selectedItem.title"
                class="form-control"
              />
            </div>
            <div class="form-group" v-if="joiner.length > 0">
              <label>Katılımcı Havuzu</label>
              <div class="card" v-for="(item, i) in joiner" :key="i">
                <div class="card-header">
                  <div style="position: absolute; left: 10px">
                    {{ item.name }}
                  </div>
                  <button
                    class="btn btn-primary"
                    style="position: absolute; right: 10px"
                    @click="addGroupJoiner(i)"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="form-group" v-if="selectedItem.join.length > 0">
              <label>Grupdaki Katılımcılar </label>
              <div class="card" v-for="(item, i) in selectedItem.join" :key="i">
                <div class="card-header">
                  <div style="position: absolute; left: 10px">
                    {{ item.name }}
                  </div>
                  <button
                    class="btn btn-primary"
                    style="position: absolute; right: 10px"
                    @click="removeGroupJoiner(i)"
                  >
                    <i class="fa fa-trash-o"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="removeGroup"
            >
              Grubu Sil
            </button>
            <button type="button" class="btn btn-secondary" @click="modalClose">
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--DATE SELECT-->
    <div class="modal" id="dateSelect" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              Seçili Tarih: {{ selectedDateItem.title }},{{
                selectedDateItem.day_name
              }}
            </h5>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Grup Seç</label>
              <select
                class="form-control"
                v-model="selectedDateItem.selectedgroup"
              >
                <option disabled selected>Seçiniz</option>
                <option v-for="(item, i) in groups" :value="i" :key="i">
                  {{ item.title }}
                </option>
              </select>
            </div>
            <div class="row">
              <div class="form-group col-6">
                <label>Başlangıç Saati</label>
                <input
                  type="time"
                  class="form-control"
                  v-model="selectedDateItem.begin"
                />
              </div>
              <div class="form-group col-6">
                <label>Bitiş Saati</label>
                <input
                  type="time"
                  class="form-control"
                  v-model="selectedDateItem.end"
                />
              </div>
            </div>
            <div class="form-group">
              <label>Not</label>
              <textarea
                class="form-control"
                placeholder="Notlar, bağlantılar, bilgilendirme..."
                v-model="selectedDateItem.memo"
              >
              </textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="createCalendarItem"
            >
              Oluştur
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDateSelect"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--DATE ITEM SELECT-->
    <div class="modal" id="dateSelectPlan" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div
              class="card p-3"
              v-for="(item, i) in createdCalendar[selectedDateItem.id]"
              :key="i"
            >
              <div class="row">
                <div class="form-group col-6">
                  <label>Başlangıç Saati</label>
                  <input
                    type="time"
                    class="form-control"
                    v-model="item.begin"
                  />
                </div>
                <div class="form-group col-6">
                  <label>Bitiş Saati</label>
                  <input type="time" class="form-control" v-model="item.end" />
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>Not</label>
                    <textarea
                      class="form-control"
                      placeholder="Notlar, bağlantılar, bilgilendirme..."
                      v-model="item.memo"
                    >
                    </textarea>
                  </div>
                  <input type="text" class="form-control" placeholder="Dosyalarda Ara" @keyup="searchFile" />
                  <div
                    style="width: fit-content; float: left"
                    v-for="(file, i) in searchFiles"
                    :key="i"
                  >
                  
                    <div
                      :class="'item '"
                      @click="selectFileAdd(item, file)"
                    >
                      <i class="fa fa-file"></i>
                      {{ file.name }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  
                  <div
                    style="width: fit-content; float: left"
                    v-for="(file, i) in files"
                    :key="i"
                  >
                  
                    <div
                      :class="
                        'item ' +
                        (item.shared_files.indexOf(file.id) > -1
                          ? 'actived'
                          : 'none')
                      "
                      @click="selectFileAdd(item, file)"
                    >
                      <i class="fa fa-file"></i>
                      {{ file.name }}
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <b>{{ item.group_name }}</b>
                  <li v-for="(a, b) in item.participant" :key="b">
                    {{ a.name }}
                  </li>
                </div>
              </div>
              <button class="btn btn-primary" @click="removeViewItem(item)">
                <i class="fa fa-trash"></i>
              </button>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              @click="closeDateSelectView"
            >
              Kapat
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.item {
  padding: 3px;
  border: 1px solid #ccc;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 10px;
  margin: 2px;
}
.none{
  display: none;
}
.item:hover {
  background: #f1f1f1;
}
.actived {
  background: #c0c0c0;
}
.grid {
  border: 3px solid rgb(53 53 53 / 5%);
  padding: 20px;
  text-align: center;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.active {
  background: rgb(255, 221, 241);
  color: black;
}
.grid:hover {
  background: rgb(255, 233, 246);
  cursor: pointer;
  color: black;
}
</style>
<script>
export default {
  created() {
    document.title = "Eğitim Planla";
    /**Eğitimi Getir*/
    this.$store
      .dispatch("educationPlanGet", { id: this.$route.params.id })
      .then((value) => {
        this.a = value;
        this.title = value[0].title;
        this.days = value[0].days;
        this.joiner = value[0].participants;
        this.groups = value[0].groups;
        this.files = value[0].files;
        this.createdCalendar = Object.assign([], value[0].calander);
      });
  },
  data() {
    return {
      a: [],
      files: [],
      title: null,
      beginDate: "",
      endDate: "",
      groups: [],
      days: [],
      selectedIndex: null,
      selectedItem: [],
      createdCalendar: [],
      selectedDateItem: [],
      joiner: [],
      searchFiles:[],
    };
  },
  methods: {
    searchFile(e){
      if(e.target.value !=""){
        this.searchFiles=[];
        this.files.forEach(a=>{
          if(a.name.search(e.target.value)>=0){
            this.searchFiles.push(a);
          }
        })
      } else {
        this.searchFiles=[];
      }
    },
    openVideoCall(item) {
      this.$router.push("/education-chat-room/" + item.id);
    },
    selectFileAdd(item, file) {
      /**STRING GELEN VERİYİ DİZİ HALİNE GETİR */
      if(typeof item.shared_files === 'string' || item.shared_files instanceof String){
        let a = item.shared_files.split(',')
        item.shared_files=[];
        a.forEach(i=>{
          item.shared_files.push(parseInt(i));
        })
      }
      let index = item.shared_files.indexOf(file.id);
      if (index > -1) {
        item.shared_files.splice(index, 1);
      } else {
        if(item.shared_files.length==0){
          item.shared_files=[]
        }
        item.shared_files.push(file.id);
      }
      this.createdCalendar.push({});
      this.createdCalendar.pop();
    },
    dateSelect(item) {
      this.selectedDateItem = item;
      $("#dateSelect").modal("show");
    },
    dateSelectPlan(item) {
      this.selectedDateItem = item;
      $("#dateSelectPlan").modal("show");
    },
    removeViewItem(item) {
      let i = 0;
      this.createdCalendar[item.date].forEach((e) => {
        if (e.date == item.date) {
          this.createdCalendar[item.date].splice(i, 1);
        } else {
          i++;
        }
      });
      this.createdCalendar.push({});
      this.createdCalendar.pop();
    },
    createCalendarItem() {
      if (this.createdCalendar[this.selectedDateItem.id] == null) {
        this.createdCalendar[this.selectedDateItem.id] = [];
      }
      this.createdCalendar[this.selectedDateItem.id].push({
        date: this.selectedDateItem.id,
        memo: this.selectedDateItem.memo,
        begin: this.selectedDateItem.begin,
        end: this.selectedDateItem.end,
        group_name: this.groups[this.selectedDateItem.selectedgroup].title,
        index: this.groups[this.selectedDateItem.selectedgroup].index,
        participant: this.groups[this.selectedDateItem.selectedgroup].join,
        shared_files: [],
      });
      $("#dateSelect").modal("hide");
      this.createdCalendar.push({});
      this.createdCalendar.pop();
    },
    newGroup() {
      this.groups.push({
        title: "Yeni Grup Başlığı",
        join: [],
        index: this.groups.length,
      });
    },
    removeGroup() {
      this.groups.splice(this.selectedIndex, 1);
      this.modalClose();
    },
    editGroupSetting(index) {
      $("#groupItemModal").modal("show");
      this.selectedItem = this.groups[index];
      this.selectedIndex = index;
    },
    addGroupJoiner(index) {
      let a=this.selectedItem.join.find(e=>e.id==this.joiner[index].id);
      if (typeof a=="undefined") {
        this.selectedItem.join.push(this.joiner[index]);
      }
    },
    removeGroupJoiner(index) {
      this.selectedItem.join.splice(index, 1);
    },
    closeDateSelect() {
      $("#dateSelect").modal("hide");
    },
    closeDateSelectView() {
      $("#dateSelectPlan").modal("hide");
    },
    modalClose() {
      $("#groupItemModal").modal("hide");
    },
    save() {
      document.getElementById("saveButton").disabled = true;
      document.getElementById("saveButton").innerHTML =
        "Eğitim planı kaydediliyor..";
      var a = Object.assign({}, this.createdCalendar);
      console.log(a);
      let educationPlan = {
        id: this.$route.params.id,
        groups: this.groups,
        calander: a,
      };
      this.$store.dispatch("educationPlanSave", educationPlan).then((value) => {
        document.getElementById("saveButton").disabled = false;
        document.getElementById("saveButton").innerHTML = "Planı Kaydet";
        if (value.type == "error") {
          if (value.message == "ERRxUNT") {
            this.$vToastify.warning(
              "Kullanıcı eğitici statüsünde değil!",
              "Uyarı!"
            );
          }
        } else {
          this.$vToastify.success("Eğitim planı kaydedildi", "Başarılı!");
        }
      });
    },
  },
  components: {},
  mounted() {},
};
</script>